<template>
    <onboarding-layout v-if="currentUserData">
        <bottom-sheet-header>
            {{ $t('userDisabled.title') }}
        </bottom-sheet-header>
        <p v-if="paymentReason(currentUserData.userinformation.disabledReason)">
            {{ $t('userDisabled.text_payment') }}
        </p>
        <p v-else>
            {{ $t('userDisabled.text_general') }}
        </p>
    </onboarding-layout>
</template>

<script>
// --- State ---
import { mapState } from 'vuex';
// --- Components ---
import OnboardingLayout from '../layouts/OnboardingLayout.vue';
import BottomSheetHeader from '@/components/BottomSheets/BottomSheetHeader';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';

import { disabledReasons } from '@/config/constants';

export default {
    components: {
        BottomSheetHeader,
        OnboardingLayout
    },

    mixins: [formRulesMixin],

    data() {
        return {};
    },

    computed: {
        ...mapState('auth', ['currentUserData'])
    },

    created() {
        if (this.currentUserData.userinformation.disabled === false) {
            return this.$router.push({ name: 'dashboard' });
        }
    },

    methods: {
        paymentReason(disabledReason) {
            return disabledReason === disabledReasons.SUBSCRIPTION_NOT_PAYED;
        }
    }
};
</script>
<style lang="scss" scoped></style>
